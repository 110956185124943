
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import {useTranslation} from "react-i18next";

import Error from "components/errors/Error";

export default function Error404() {
  const {t} = useTranslation();
  return (
    <Error title={t("app.error.code404")}>{t("app.error.notFound")}</Error>
  );
}
